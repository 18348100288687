'use client'; // Error components must be Client Components

import {useCallback, useEffect} from 'react';
import clsx from 'clsx';

export default function Error({error, reset}: {error: Error; reset: () => void}) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
	}, [error]);

	const handleClick = useCallback(() => {
		// Attempt to recover by trying to re-render the segment
		reset();
	}, [reset]);

	return (
		<>
			<title>500 - noe gikk galt</title>
			<div
				className={clsx(
					'px-5',
					'py-20',
					'flex',
					'flex-col',
					'justify-center',
					'items-center',
					'h-full',
					'gap-9',
					'text-center',
				)}
			>
				<h1>Oops, noe gikk galt!</h1>
				<button
					onClick={handleClick}
					className={clsx(
						'bg-black',
						'hover:bg-black/70',
						'text-white',
						'px-6',
						'py-3',
						'inline-block',
						'rounded-md',
						'text-center',
						'w-full',
						'md:w-auto',
						'transition-colors',
					)}
				>
					Prøv igjen
				</button>
			</div>
		</>
	);
}
